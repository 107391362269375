// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-angebot-tsx": () => import("./../src/pages/angebot.tsx" /* webpackChunkName: "component---src-pages-angebot-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-portrait-tsx": () => import("./../src/pages/portrait.tsx" /* webpackChunkName: "component---src-pages-portrait-tsx" */),
  "component---src-pages-projekte-tsx": () => import("./../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-pages-warum-tsx": () => import("./../src/pages/warum.tsx" /* webpackChunkName: "component---src-pages-warum-tsx" */)
}

